// HP 15G
// export const API_ROOT_URL = "http://devtrek.ddns.net:5010";
// Ryzen 3600
// export const API_ROOT_URL = "http://devtrek.ddns.net:5021";
// UAT
// export const API_ROOT_URL = "https://uat.1l-srv.com";
// Production
export const API_ROOT_URL = "https://1l-srv.com";
export const ACCEPT_VERSION_HEADER = "1.0.3";
export const ACCEPT_LANGUAGE_HEADER = "en-US";

export const APP_STORE_LINK_IOS  = "https://apps.apple.com/us/app/onelinc/id1539300207";
export const APP_STORE_LINK_ANDROID  = "https://play.google.com/store/apps/details?id=net.onelinc.addressbook";
