
import { Component, Vue } from "vue-property-decorator";
import { TopNavBar, Footer } from "@/components";

@Component({
  components: {
    TopNavBar,
    Footer,
  },
})
export default class App extends Vue {}
