
import { Component, Vue } from "vue-property-decorator";
import { RouteDefs } from "@/router/RouteDefs";
import { APP_STORE_LINK_IOS, APP_STORE_LINK_ANDROID } from "@/config";
import { default as Jumbotron } from "@/views/home/Jumbotron.vue";
import { default as FeatureOneLincKey } from "@/views/home/FeatureOneLincKey.vue";
import { default as FeatureAddressBook } from "@/views/home/FeatureAddressBook.vue";
import { default as FeatureInfoManager } from "@/views/home/FeatureInfoManager.vue";

@Component({
  components: {
    Jumbotron,
    FeatureOneLincKey,
    FeatureAddressBook,
    FeatureInfoManager,
  },
})
export default class Home extends Vue {
  public readonly appStoreLinkAndroid = APP_STORE_LINK_ANDROID;
  //public readonly appStoreLinkAndroid = RouteDefs.ComingSoon.name;
  //public readonly appStoreLinkIOS = APP_STORE_LINK_IOS;
  public readonly appStoreLinkIOS = RouteDefs.ComingSoon.name;
}
