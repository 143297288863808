
import { Component, Vue } from "vue-property-decorator";
import { RouteDefs } from "@/router/RouteDefs";

@Component
export default class TopNavBar extends Vue {
  // private sidebar = false;

  private get homeRouteName() {
    return RouteDefs.Home.name;
  }

  private get menuItems() {
    return [
      {
        title: "Who We Are",
        routeName: RouteDefs.AboutUs.name,
        icon: "mdi-help-circle",
        iconColor: "#838383",
      },
      //   {
      //     title: "Services",
      //     routeName: RouteDefs.Services.name,
      //     icon: "mdi-cog",
      //     iconColor: "#838383",
      //   },
      //   {
      //     title: "Register",
      //     routeName: RouteDefs.Register.name,
      //     icon: "mdi-account-circle",
      //     iconColor: "#38bd38",
      //   },
      //   {
      //     title: "Login",
      //     routeName: RouteDefs.Login.name,
      //     icon: "mdi-lock-open",
      //     iconColor: "#2f82e8",
      //   },
    ];
  }
}
