import Vue from "vue";
import VueRouter from "vue-router";
import App from "@/App.vue";
import router from "@/router";
import vuetify from './plugins/vuetify';
import VueMq from 'vue-mq';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueMq, {
	// TODO: keep these breakpoint values
	// in sync with what's defines in views/css_vars.scss
	breakpoints: {
		xsm: 500,
		sm: 800,
		md: 1200,
		lg: Infinity,
	},
	defaultBreakpoint: 'sm' // customize this for SSR
});
Vue.use(Loading);

new Vue({
	router,
	vuetify,
	render: h => h(App)
}).$mount('#app');
