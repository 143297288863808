
import { Component, Vue } from "vue-property-decorator";
import { RouteDefs } from "@/router/RouteDefs";

@Component
export default class Footer extends Vue {
  private get menuItems() {
    return [
      {
        title: "Contact us",
        routeName: RouteDefs.ContactUs.name,
      },
      {
        title: "Investors",
        routeName: RouteDefs.Investors.name,
      },
      {
        title: "Terms and Privacy Policy",
        routeName: RouteDefs.TermsOfUseAndPrivacyPolicy.name,
      },
    ];
  }
}
