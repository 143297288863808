
import axios from "axios";
import { Component, Vue, Ref } from "vue-property-decorator";
import { VForm } from "@/types";
import {
  API_ROOT_URL,
  ACCEPT_VERSION_HEADER,
  ACCEPT_LANGUAGE_HEADER,
} from "@/config";
import { RouteDefs } from "@/router/RouteDefs";
import { ConfirmDialog } from "@/components";

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class ContactUs extends Vue {
  @Ref("form") readonly form!: VForm;

  public static readonly OneLincKeyRegExp: RegExp = new RegExp(
    /^([AC-HJ-NP-RT-Y0-9]){9}$/
  );

  public static readonly EmailRegExp: RegExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  // Keep values in sync in API
  private readonly firstNameMaxLength = 64;
  private readonly lastNameMaxLength = 64;
  private readonly emailMaxLength = 128;
  private readonly phoneNumberMaxLength = 32;
  private readonly oneLincKeyMaxLength = 11;
  private readonly titleMaxLength = 128;
  private readonly commentMaxLength = 1024;

  // Keep in sync with API
  private readonly reasons = [
    { reason: "Bug/issue", reasonCode: "issue" },
    { reason: "Question/comment", reasonCode: "comment_question" },
    { reason: "Security/privacy", reasonCode: "security_privacy" },
    { reason: "Other", reasonCode: "other" },
  ];

  private firstName = "";
  private lastName = "";
  private oneLincKey = "";
  private phoneNumber = "";
  private email = "";
  private reason = "";
  private title = "";
  private comment = "";

  private firstNameRules = [
    (v: string) =>
      v.length <= this.firstNameMaxLength ||
      `${this.firstNameMaxLength} characters maximum`,
    (v: string) => !!v.trim() || "First Name is required",
  ];

  private lastNameRules = [
    (v: string) =>
      v.length <= this.lastNameMaxLength ||
      `${this.lastNameMaxLength} characters maximum`,
    (v: string) => !!v.trim() || "Last Name is required",
  ];

  private emailRules = [
    (v: string) =>
      v.length <= this.emailMaxLength ||
      `${this.emailMaxLength} characters maximum`,
    (v: string) => !!v.trim() || "E-mail is required",
    (v: string) => ContactUs.EmailRegExp.test(v.trim()) || "Invalid email",
  ];

  private phoneNumberRules = [
    (v: string) =>
      v.length <= this.phoneNumberMaxLength ||
      `${this.phoneNumberMaxLength} characters maximum`,
  ];

  private oneLincKeyRules = [
    (v: string) =>
      v.length <= this.oneLincKeyMaxLength ||
      `${this.oneLincKeyMaxLength} characters maximum`,
    (v: string) =>
      v.trim() == "" ||
      ContactUs.OneLincKeyRegExp.test(this.filterOneLincKey(v)) ||
      `Invalid OneLINC key`,
  ];

  private reasonRules = [(v: string) => !!v || "Reason is required"];

  private titleRules = [
    (v: string) =>
      v.length <= this.titleMaxLength ||
      `${this.titleMaxLength} characters maximum`,
    (v: string) => !!v.trim() || "Title/Short description is required",
  ];

  private commentRules = [
    (v: string) =>
      v.length <= this.commentMaxLength ||
      `${this.commentMaxLength} characters maximum`,
    (v: string) => !!v.trim() || "Comment is required",
  ];

  private filterOneLincKey(v: string): string {
    return (v || "").replace(/[^0-9A-Za-z]/g, "").toUpperCase();
  }

  private submit() {
    if (this.form.validate()) {
      const loader = this.$loading.show({
        canCancel: false,
      });

      const paylaod = {
        // Keep in sync with back end
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        oneLincKey: this.filterOneLincKey(this.oneLincKey),
        email: this.email.trim(),
        phoneNumber: this.phoneNumber.trim(),
        source: "web", // Keep in sync with API (this is defined in 'PlatformType' enum in API)
        reason: this.reason,
        title: this.title.trim(),
        comment: this.comment.trim(),
      };

      axios
        .post(`${API_ROOT_URL}/contact-us/v1`, paylaod, {
          headers: {
            "accept-version": ACCEPT_VERSION_HEADER,
            "accept-language": ACCEPT_LANGUAGE_HEADER,
          },
        })
        .then(async (response) => {
          if (response.data.responseCode == 200) {
            loader.hide();

            await (this.$refs.confirm as ConfirmDialog).open(
              "Request submitted",
              "Thank you. You will be contacted shortly.",
              {
                noConfirm: true,
              }
            );

            this.$router.push({ name: RouteDefs.Home.name });
          } else {
            loader.hide();

            (this.$refs.confirm as ConfirmDialog).open(
              "Error",
              "An unexpected error occurred. Please try again.",
              {
                noConfirm: true,
              }
            );
          }
        })
        .catch(() => {
          loader.hide();

          (this.$refs.confirm as ConfirmDialog).open(
            "Error",
            "An unexpected error occurred. Please try again.",
            {
              noConfirm: true,
            }
          );
        });
      //.finally(() => loader.hide());
    }
  }

  private created() {
    const qreason = this.$route.query.reason;

    if (qreason) {
      const r = this.reasons.find((r) => r.reasonCode === qreason);

      if (r) {
        this.reason = r.reasonCode;
      }
    }
  }
}
