
import { Component, Vue } from "vue-property-decorator";
import { RouteDefs } from "@/router/RouteDefs";
import { APP_STORE_LINK_IOS, APP_STORE_LINK_ANDROID } from "@/config";

@Component
export default class Jumbotron extends Vue {
  public readonly appStoreLinkAndroid = APP_STORE_LINK_ANDROID;
  //public readonly appStoreLinkIOS = APP_STORE_LINK_IOS;
  //public readonly appStoreLinkAndroid = RouteDefs.ComingSoon.name;
  public readonly appStoreLinkIOS = RouteDefs.ComingSoon.name;
}
