
import { Component, Vue } from "vue-property-decorator";

type optionsType = {
  color?: string;
  width?: number;
  zIndex?: number;
  noConfirm?: boolean;
};

@Component
export default class ConfirmDialog extends Vue {
  private static defaultOptions: optionsType = {
    color: "grey lighten-3",
    width: 400,
    zIndex: 200,
    noConfirm: false,
  };

  private dialog = false;
  private title = "";
  private message = "";
  private options: optionsType = ConfirmDialog.defaultOptions;
  private resolve: ((v: boolean) => void) | null = null;
  private reject: ((err: unknown) => void) | null = null;

  public open(title: string, message: string, options?: optionsType) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(
      this.options,
      options || ConfirmDialog.defaultOptions
    );

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public agree() {
    if (this.resolve) {
      this.resolve(true);
    }
    this.dialog = false;
  }

  public cancel() {
    if (this.resolve) {
      this.resolve(false);
    }
    this.dialog = false;
  }
}
