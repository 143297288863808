
// Make sure to keep in sync with backend (models, schemas, etc...)
import axios from "axios";
import { Component, Vue, Ref } from "vue-property-decorator";
import { VForm } from "@/types";
import {
  API_ROOT_URL,
  ACCEPT_VERSION_HEADER,
  ACCEPT_LANGUAGE_HEADER,
} from "@/config";
import { RouteDefs } from "@/router/RouteDefs";
import { ConfirmDialog } from "@/components";

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class Investors extends Vue {
  @Ref("form") readonly form!: VForm;

  // Keep values in sync in API
  private firstNameMaxLength = 64;
  private lastNameMaxLength = 64;
  private phoneNumberMaxLength = 32;
  private companyMaxLength = 64;
  private websiteMaxLength = 256;
  private portfolioMaxLength = 1024;

  private firstName = "";
  private lastName = "";
  private phoneNumber = "";
  private companyName = "";
  private website = "";
  private investment = "";
  private portfolioDescription = "";

  private investments = [
    {
      label: "USD 250,000 - 500,000",
      value: "USD250K-500K",
    },
    {
      label: "USD 500,000 - 1,000,000",
      value: "USD500K-1M",
    },
    {
      label: "USD 1,000,000 - 2,000,000",
      value: "USD1M-2M",
    },
    {
      label: "USD 2,000,000 or more",
      value: "USD2M+",
    },
  ];

  private firstNameRules = [
    (v: string) => !!v.trim() || "This field is required",
    (v: string) =>
      v.length <= this.firstNameMaxLength ||
      `${this.firstNameMaxLength} characters maximum`,
  ];

  private lastNameRules = [
    (v: string) => !!v.trim() || "This field is required",
    (v: string) =>
      v.length <= this.lastNameMaxLength ||
      `${this.lastNameMaxLength} characters maximum`,
  ];

  private phoneNumberRules = [
    (v: string) => !!v.trim() || "This field is required",
    (v: string) =>
      v.length <= this.phoneNumberMaxLength ||
      `${this.phoneNumberMaxLength} characters maximum`,
  ];

  private companyRules = [
    (v: string) => !!v.trim() || "This field is required",
    (v: string) =>
      v.length <= this.companyMaxLength ||
      `${this.companyMaxLength} characters maximum`,
  ];

  private websiteRules = [
    (v: string) => !!v.trim() || "This field is required",
    (v: string) =>
      v.length <= this.websiteMaxLength ||
      `${this.websiteMaxLength} characters maximum`,
  ];

  private investmentRules = [(v: string) => !!v || "This field is required"];

  private portfolioRules = [
    (v: string) => !!v.trim() || "This field is required",
    (v: string) =>
      v.length <= this.portfolioMaxLength ||
      `${this.portfolioMaxLength} characters maximum`,
  ];

  // eslint-disable-next-line
  private submit(event: Event) {
    if (this.form.validate()) {
      const loader = this.$loading.show({
        canCancel: false,
      });

      const data = {
        // Keep in sync with back end
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        phoneNumber: this.phoneNumber.trim(),
        companyName: this.companyName.trim(),
        website: this.website.trim(),
        investment: this.investment,
        portfolioDescription: this.portfolioDescription.trim(),
      };

      axios
        .post(`${API_ROOT_URL}/contact-us/v1/investor`, data, {
          headers: {
            "accept-version": ACCEPT_VERSION_HEADER,
            "accept-language": ACCEPT_LANGUAGE_HEADER,
          },
        })
        .then(async (response) => {
          if (response.data.responseCode == 200) {
            loader.hide();

            await (this.$refs.confirm as ConfirmDialog).open(
              "Information submitted",
              "Thank you for your interest in OneLINC.",
              {
                noConfirm: true,
              }
            );

            this.$router.push({ name: RouteDefs.Home.name });
          } else {
            loader.hide();

            (this.$refs.confirm as ConfirmDialog).open(
              "Error",
              "An unexpected error occurred. Please try again.",
              {
                noConfirm: true,
              }
            );
          }
        })
        .catch(() => {
          loader.hide();

          (this.$refs.confirm as ConfirmDialog).open(
            "Error",
            "An unexpected error occurred. Please try again.",
            {
              noConfirm: true,
            }
          );
        });
      //.finally(() => loader.hide());
    }
  }
}
