export type RouteDef = { name: string; path: string };

export abstract class RouteDefs {
	static Home: RouteDef = {
		name: 'home',
		path: '/',
	};

	static AboutUs: RouteDef = {
		name: "about-us",
		path: "/about-us",
	};

	static ContactUs: RouteDef = {
		name: "contact-us",
		path: "/contact-us",
	};

	// static Services: RouteDef = {
	// 	name: "services",
	// 	path: "/services",
	// };

	// static Register: RouteDef = {
	// 	name: "register",
	// 	path: "/register",
	// };

	// static Login: RouteDef = {
	// 	name: "login",
	// 	path: "/login",
	// };

	static Investors: RouteDef = {
		name: "investors",
		path: "/investors",
	};

	static TermsOfUseAndPrivacyPolicy: RouteDef = {
		name: "terms-of-use-and-privacy-policy",
		path: "/terms-of-use-and-privacy-policy",
	};

	static ChainBlock: RouteDef = {
		name: "chain-block",
		path: "/chain-block",
	};

	// Firebase dynamic link fallback page from invite sent to manual contact
	// IMPORTANT!!! Don't change path! It must match definition in Firebase Dynamic link
	static InviteContact: RouteDef = {
		name: "invite-contact",
		path: "/join/invite-contact",
	};

	// Firebase dynamic link fallback page from invite sent to a friend
	// IMPORTANT!!! Don't change path! It must match definition in Firebase Dynamic link
	static InviteFriend: RouteDef = {
		name: "invite-friend",
		path: "/join/invite-friend",
	};

	static ComingSoon: RouteDef = {
		name: "coming-soon",
		path: "/coming-soon",
	};

	static PageNotFound: RouteDef = {
		name: "not-found",
		path: "*",
	};
}
