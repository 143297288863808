import VueRouter from "vue-router";

import {
	HomeView,
} from "@/views";

import { RouteDefs } from "@/router/RouteDefs";

const routes = [ 			//]: Array<RouteRecordRaw> = [
	{
		path: RouteDefs.Home.path,
		name: RouteDefs.Home.name,
		component: HomeView,
	},
	{
		path: RouteDefs.AboutUs.path,
		name: RouteDefs.AboutUs.name,
		component: () => import(/* webpackChunkName: "AboutUs" */ "@/views/AboutUs.vue"),
	},
	{
		path: RouteDefs.ContactUs.path,
		name: RouteDefs.ContactUs.name,
		component: () => import(/* webpackChunkName: "ContactUs" */ "@/views/ContactUs.vue"),
	},
	// {
	// 	path: RouteDefs.Services.path,
	// 	name: RouteDefs.Services.name,
	// 	component: () => import(/* webpackChunkName: "Services" */ "@/views/Services.vue"),
	// },
	// {
	// 	path: RouteDefs.Register.path,
	// 	name: RouteDefs.Register.name,
	// 	component: () => import(/* webpackChunkName: "Register" */ "@/views/Register.vue"),
	// },
	// {
	// 	path: RouteDefs.Login.path,
	// 	name: RouteDefs.Login.name,
	// 	component: () => import(/* webpackChunkName: "Login" */ "@/views/login/Login.vue"),
	// },
	{
		path: RouteDefs.Investors.path,
		name: RouteDefs.Investors.name,
		component: () => import(/* webpackChunkName: "Investors" */ "@/views/Investors.vue"),
	},
	{
		path: RouteDefs.TermsOfUseAndPrivacyPolicy.path,
		name: RouteDefs.TermsOfUseAndPrivacyPolicy.name,
		component: () => import(/* webpackChunkName: "TermsOfUseAndPrivacyPolicy" */ "@/views/TermsOfUseAndPrivacyPolicy.vue"),
	},
	{
		path: RouteDefs.ChainBlock.path,
		name: RouteDefs.ChainBlock.name,
		component: () => import(/* webpackChunkName: "ChainBlock" */ "@/views/ChainBlock.vue"),
	},
	{
		// Firebase dynamic link fallback page from invite sent to manual contact
		// IMPORTANT!!! Don't change path! It must match definition in Firebase Dynamic link
		path: RouteDefs.InviteContact.path,
		name: RouteDefs.InviteContact.name,
		component: () => import(/* webpackChunkName: "InviteContact" */ "@/views/marketing/InviteContact.vue"),
	},
	{
		// Firebase dynamic link fallback page from invite sent to a friend
		// IMPORTANT!!! Don't change path! It must match definition in Firebase Dynamic link
		path: RouteDefs.InviteFriend.path,
		name: RouteDefs.InviteFriend.name,
		component: () => import(/* webpackChunkName: "InviteFriend" */ "@/views/marketing/InviteFriend.vue"),
	},
	{
		path: RouteDefs.ComingSoon.path,
		name: RouteDefs.ComingSoon.name,
		component: () => import(/* webpackChunkName: "ComingSoon" */ "@/views/ComingSoon.vue"),
	},
	{ 
		path: RouteDefs.PageNotFound.path,
		name: RouteDefs.PageNotFound.name,
		component: () => import(/* webpackChunkName: "ComingSoon" */ "@/views/PageNotFound.vue"),
	},
];

const router = new VueRouter({
	routes: routes,
	mode: 'history',
	// eslint-disable-next-line 
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 }
	},
});

export default router;
